import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './searchable_list.css';

const SearchItem = (props) => {
  return (
    <div className="search_item">
      <a className="search_link" href={props.link}>{props.item}</a>
    </div>
  )
}

const SearchableList = (props) => {
  const [searchTerm, setSearchTerm] = useState(''); // State to track search input
  const navigate = useNavigate(); // React Router's navigation hook for internal routing

  // Filter the list based on the search term
  const filteredList = props.data.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle enter key event to select the first item
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && filteredList.length > 0) {
        let firstItem = filteredList[0]
        // Check if it's an internal or external link
        if (firstItem.link.startsWith('http')) {
          window.location.href = firstItem.link; // External link
        } else {
          navigate(firstItem.link); // Internal link with React Router
        }
      }
    };

    // Add keydown event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [filteredList, navigate]);

  return (
    <div className="searchable_list">
      <div className='search_box_container'>
        <input
          className='search_box'
          autoFocus
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className='search_list_container'>
        <ul className='search_list'>
          {filteredList.length > 0 ? (
            filteredList.map((item, index) => <SearchItem key={index} item={item.title} link={item.link} />)
          ) : (
            <li className="idiom_not_found">No items found</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SearchableList;
