import React from 'react';

import './layout_v1.css';
import { Header } from './header';
import { MainBody } from './main_body';
import { Footer } from './footer';

export const LayoutV1 = () => {
  return (
    <div className="layout">
      <Header />
      <MainBody />
      <Footer />
    </div>
  )
}
