import React from "react";
import './Idioms.css';

import IdiomSearchableList from './idioms_searchable_list.js'

const new_item_list = [
  "The first pancake alway goes wrong",
  "You can't make an omlette without breaking a few eggs",
  "Standing on the shoulders of giants",
  "A sprat to catch a mackerel",
  "God gives his toughest battles to his strongest soldiers",
  "The proof is in the pudding",
  "Don't beat around the bush",
  "Don't judge a book by its cover",
  "Too many cooks spoil the broth",
  "Nothing to write home about",
  "Grinding my gears",
  "Shoot yourself in the foot",
  "Beggars can't be choosers",
  "Even a broken clock is right twice a day",
  "The blind leading the blind ",
  "The lights are on but nobody's home",
  "What goes around comes around",
  "The early bird gets the worm",
  "Not the sharpest tool in the shed",
  "The grass is always greener on the other side",
  "It ain't over until the fat lady sings",
  "Don’t have a leg to stand on",
  "When life gives you lemons make lemonade",
  "Bit off more than you can chew",
  "You've got the wrong end of the stick",
  "Beauty is in the eye of the beholder",
  "ON THE ROCKS",
  "Smooth seas don't make good sailors",
  "Comparison is the thief of joy",
  "Like water off a ducks back",
  "Don't know your ass from your elbow",
  "Look after the pennies and the pounds will look after themselves",
  "You either die a hero or live long enough to see yourself become a villan",
  "The tail is wagging the dog",
  "A picture is worth a thousand words",
  "Passing like ships in the night",
  "Too little too late",
  "Not my circus not my monkey",
  "Time is of the essence",
  "Hold your horses",
  "Put your money where your mouth is",
  "Don't put all your eggs in one basket",
  "Have fingers in many pies",
  "Immitation is the sincerest form of flattery",
  "Pencil it in",
  "Hit the nail on the head",
  "It's raining cats and dogs",
  "Kill two birds with one stone",
  "A bird in the hand is worth two in the bush",
  "Like shit off a shovel",
  "Save my bacon",
  "Walk in the park",
  "Get your ducks in a row",
  "Good as gold",
  "Blessing in disguise",
  "A diamond in the rough",
  "Put the cat among the pigeons",
  "Burning the candle at both ends",
  "Skin in the game",
  "The world is your oyster",
  "Rules the roost",
  "Make no bones about it",
  "Give them an inch and they will take a mile",
  "Don't hate the player hate the game",
  "Pulling my leg",
  "Twisting my arm",
  "Coming out swinging",
  "Rain in paradise",
  "Take the rough with the smooth",
  "Like a fish out of water",
  "Live and learn",
  "What doesn't kill you makes you stronger",
  "Elephant in the room",
  "Speak of the devil and he shall appear",
  "Two steps forward one step back",
  "You can lead a horse to water but you can't make it drink",
  "Up against the clock",
  "Under the cosh",
  "Too much on your plate",
  "Whatever floats your boat",
  "Don't want to split hairs",
  "People who live in glass houses shouldn't throw stones",
  "No skin off my back",
  "That's the badger",
  "Bigger fish to fry",
  "By a cats whisker",
  "Knee high to a grasshopper",
  "Iron it out",
  "Rarer than rocking horse crap",
  "More than one way to skin a cat",
  "The naked man fears no pickpocket",
  "Without a shadow of a doubt",
  "Jump and the net shall appear",
  "Plenty more fish in the sea",
  "A run for your money",
  "Closing the stable door after the horse has bolted",
]

class IdiomPage extends React.Component {
  render() {
    return (
      <div className="IdiomPage">
        <header className="idiom_page_header">
          <h1 className="IdiomTitle">euan's website</h1>
        </header>
        <div className="idiom_body">
          <IdiomSearchableList data={new_item_list} />
        </div>
      </div>
    );
  }
}


export default IdiomPage;
