import React, { useState } from 'react';

import './idioms_searchable_list.css';

const IdiomSearchItem = (props) => {
  return (
    <div className="idiom_search_item">
      <a className="idiom_search_link" href={props.link}>{props.item}</a>
    </div>
  )
}

const IdiomSearchableList = (props) => {
  const [searchTerm, setSearchTerm] = useState(''); // State to track search input

  // Filter the list based on the search term
  const filteredList = props.data.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="idiom_searchable_list">
      <div className='search_box_container'>
        <input
          className='search_box'
          autoFocus
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="idiom_count">Count: {props.data.length}</div>
      <div className='idiom_search_list_container'>
        <ul className='idiom_search_list'>
          {filteredList.length > 0 ? (
            filteredList.map((item, index) => <IdiomSearchItem key={index} item={item} />)
          ) : (
            <li className="idiom_not_found">No items found</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default IdiomSearchableList;
