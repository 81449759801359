import React from "react";

import './front_page_tile.css';
import { Separator } from './separator';

export const FrontPageTile = (props) => {
  return (
    <div className="tile_container" id={props.id}>
      <Separator />
      <div className="tile_title_container">
        <h1 className="tile_title">{props.title}</h1>
      </div>
      <Separator />
      <div className="tile_body">
        <div className="tile_image_container" style={{ order: !props.left_image ? 1 : 0 }}>
          <img src={props.image} class='tile_image' alt="Ali holding a dish" />
        </div>
        <div className="tile_text_container" style={{ order: props.left_image ? 1 : 0 }}>
          <div className="inner_text_container">
            <p className="tile_text">{props.text}</p>
          </div>
        </div>
      </div >
    </div >
  )
}
