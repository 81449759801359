import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import image from '../assets/menu_image.png';
import './gallery_tile.css'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export const GalleryTile = (props) => {
  return (
    <div className="gallery_tile_container" id={props.id}>
      <div className="carousel">
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          infinite={true}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="carousel_container"><img src={image} alt="food" /></div>
          <div className="carousel_container"><img src={image} alt="food" /></div>
          <div className="carousel_container"><img src={image} alt="food" /></div>
          <div className="carousel_container"><img src={image} alt="food" /></div>
          <div className="carousel_container"><img src={image} alt="food" /></div>
        </Carousel>
      </div>
    </div>
  )
}
