import React from "react";

import './main_body.css';
import about_us_image from "../assets/about_us_image.png";
import menu_image from '../assets/menu_image.png';
import restraunt_image from '../assets/restraunt_image.png'
import { FrontPageTile } from "../components/front_page_tile";
import { Separator } from "../components/separator";
import { GalleryTile } from "../components/gallery_tile";

export const MainBody = () => {
  return (
    <div class='main_body'>
      <li class='main_body_list'>
        <Separator />
        <GalleryTile id="gallery" title="Gallery" />
        <FrontPageTile id="about_us" image={about_us_image} left_image='true' title='Our Story' text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"} />
        <FrontPageTile id="menu" image={menu_image} title='Menu' text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"} />
        <FrontPageTile id="find_us" image={restraunt_image} left_image='true' title='Where to find us' text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"} />
        <FrontPageTile id="tickets" image={about_us_image} title='Tickets' text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"} />
      </li >
    </div >
  )
}
