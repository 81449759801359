import React from 'react';

import './header.css';
import title_logo from '../assets/title_logo.jpg';

export const Header = () => {
  return (
    <div className="header">
      <div className="header_menu">
        <li className="menu_list">
          <a className="menu_item" href="#about_us"><p>Our Story</p></a>
          <a className="menu_item" href="#menu"><p>Menu</p></a>
          <a className="menu_item" href="#find_us"><p>Find Us</p></a>
          <a className="menu_item" href="#tickets"><p>Tickets</p></a>
        </li>
      </div>
      <div className='header_logo_container'>
        <img src={title_logo} class='title_logo' alt='Rasoi Logo' />
      </div>
    </div>
  )
}
