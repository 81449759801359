import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for missing marker icon
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});


L.Marker.prototype.options.icon = DefaultIcon;

const MapComponent = () => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const position = [51.461, -2.6084];  // Latitude and Longitude for the map center

  useEffect(() => {
    const fetchGeoJson = async () => {
      try {
        const response = await fetch('/all_activities.geojson');  // Make sure the path is correct
        const data = await response.json();
        setGeoJsonData(data);
      } catch (error) {
        console.error('Error loading GeoJSON:', error);
      }
    };
    fetchGeoJson();
  }, []);

  return (
    <MapContainer center={position} zoom={15} style={{ height: "100vh", width: "100%" }} preferCanvas={true} >
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
        minZoom={5}
        maxZoom={17}
        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {/* Only render GeoJSON once the data is loaded */}
      {geoJsonData && (
        <GeoJSON data={geoJsonData} style={{ color: 'blue', weight: 3 }} preferCanvas={true} />
      )}
    </MapContainer>
  );
};

export default MapComponent;

