import React from 'react';

import './home.css'

import MapComponent from './components/map.js';


export const RunYourCity = () => {
  return (
    <div className="ryc_home">
      <MapComponent />
    </div>
  )
}
